import * as common from '../common'
const mqtt = require('mqtt')

export const host = 'wss://' + common.emqxServerName
window.mqttClient = null
export const getClient = async function (config, cb) {
  // let cb = arguments[arguments.length - 1];
  if (window.mqttClient) {
    if (window.mqttClient.connected) {
      typeof cb === 'function' && cb(null, window.mqttClient)
      return window.mqttClient
    } else {
      window.mqttClient.end()
      window.mqttClient = null
    }
  }
  return new Promise(function (resolve, reject) {
    config = config || {}
    const clientID = config.clientID || 'h5-' + new Date().getTime() + Math.random().toString().substr(3, 5)
    const options = {
      protocolVersion: 4,
      clientId: clientID,
      // path: '/mqtt',
      username: config.username || 'username',
      password: config.password || 'password',
      reconnectPeriod: config.reconnectPeriod || 500,
      connectTimeout: config.connectTimeout || 1000,
      reschedulePings: true,
      resubscribe: false,
      keepalive: 30
      // useSSL: config.useSSL || true,
    }
    console.log('host', host, mqtt)

    const client = mqtt.connect(host, options)
    client.callback = client.callback || {}
    client.topics = []
    // client.is_connected = false
    client.on('connect', function (connack) {
      console.log('on connect doing', connack)
      if (connack) {
        // client.is_connected = true
        console.log('connack', connack)

        config && typeof config.onConnected === 'function' && config.onConnected(connack)
        return resolve(client)
      }
    })
    client.on('error', function (error) {
      console.log('mqtt-err', error)
      // eslint-disable-next-line standard/no-callback-literal
      typeof cb === 'function' && cb('连接出错请刷新', window.mqttClient)
      reject(error)
    })

    console.log('I\'m doing')
    window.mqttClient = client
    client.on('offline', function () {
      // client.is_connected = false
      console.log('on offline')
      // eslint-disable-next-line standard/no-callback-literal
      typeof cb === 'function' && cb('离线请刷新', window.mqttClient)
      reject(new Error('离线请刷新'))
    })

    client.on('message', function (topic, message, packet) {
      console.log('我收到的msg', '----', topic, message.toString(), packet)
      const strMessage = message.toString()
      if (/^\{.*\}$/.test(strMessage)) {
        const msg = JSON.parse(strMessage)
        console.log(
          '!msg.vesion || msg.version == \'v1\'', !msg.version || msg.version === 'v1'
        )
        if (!msg.version || msg.version === 'v1') {
          typeof config.onMessage === 'function' && config.onMessage(topic, message, packet)
        } else {
          console.log('msg.topic ', msg.topic)
          typeof client.callback[msg.topic] === 'function' && client.callback[msg.topic](topic, msg, packet)
        }
      } else {
        console.log('message has\'t parse')
      }
    })

    client.on('reconnect', function () {
      // console.log('reconnect');
      // client.is_connected = true
      // typeof config.onReconnect === 'function' && config.onReconnect('sq');
      resubscribe(function (err) {
        if (err) return reject(err)
        resolve(client)
      })
    })
    client.on('disconnect', function () {
      resolve(client)
    })
  })
}

export const resubscribe = function (cb) {
  if (window.mqttClient) {
    if (window.mqttClient.topics.length) {
      window.mqttClient.subscribe(window.mqttClient.topics, function (err, granted) {
        if (err) return cb(err)
        cb(null, granted)
      })
    } else {
      cb(null)
    }
  }
}
const leaveWord = '车场离线'
export const subscribe = function (topic, options, callback) {
  return new Promise(function (resolve, reject) {
    if (window.mqttClient) {
      if (!window.mqttClient.connected) {
        typeof callback === 'function' && callback(leaveWord)
        reject(leaveWord)
      }
      window.mqttClient.subscribe(topic, options, function (err, granted) {
        if (window.mqttClient.topics.indexOf(topic) === -1) window.mqttClient.topics.push(topic)
        if (err) {
          typeof callback === 'function' && callback(err)
          reject(err)
        }
        typeof callback === 'function' && callback(null, granted)
        resolve()
      })
    } else {
      reject(new Error('network err'))
    }
  })
}
export const unsubscribe = function (topic, options, callback) {
  return new Promise(function (resolve, reject) {
    options = options || { qos: 1 }
    if (window.mqttClient) {
      if (!window.mqttClient.connected) {
        typeof callback === 'function' && callback(leaveWord)
        reject(leaveWord)
      }
      window.mqttClient.unsubscribe(topic, options, function (err) {
        if (err) {
          typeof callback === 'function' && callback(err)
          return reject(err)
        }
        if (window.mqttClient.topics.indexOf(topic) !== -1) {
          window.mqttClient.topics.splice(window.mqttClient.topics.indexOf(topic), 1)
        }
        typeof callback === 'function' && callback(null)
        resolve()
      })
    }
  })
}
export const publish = function (topic, message, options, callback) {
  // topic is the topic to publish to, String
  // message is the message to publish, Buffer or String
  // options is the options to publish with, including:
  //    qos QoS level, Number, default 0
  //    retain retain flag, Boolean, default false
  //    dup mark as duplicate flag, Boolean, default false
  //    cbStorePut - function (), fired when message is put into outgoingStore if QoS is 1 or 2.
  // let app = getApp();
  // let mqttClient = localStorage.getItem('mqttClient')
  // options = {qos: 0}
  window.mqttClient && window.mqttClient.publish(topic, message, options, function (err) {
    console.log('publish-err', err)
    if (err) {
      callback(err)
    } else {
      callback(null)
    }
  })
}
export const close = function (callback) {
  window.mqttClient && window.mqttClient.end(function (err) {
    console.log('err-close', err)
    window.mqttClient = null
    typeof callback === 'function' && callback(err)
  })
}
export const generateOrder = function (sid) {
  const date = new Date()
  const y = date.getFullYear()
  let m = date.getMonth() + 1
  let d = date.getDate()
  let h = date.getHours()
  let min = date.getMinutes()
  let s = date.getSeconds()
  if (m >= 1 && m <= 9) {
    m = '0' + m
  }
  if (d >= 0 && d <= 9) {
    d = '0' + d
  }
  if (h >= 0 && h <= 9) {
    h = '0' + h
  }
  if (min >= 0 && min <= 9) {
    min = '0' + min
  }
  if (s >= 0 && s <= 9) {
    s = '0' + s
  }
  const rm = Math.random().toString().substr(3, 6)
  return sid + '/' + y + m + d + h + min + s + '/' + rm // 格式：2018-12-18 02:11
}
